import PerformanceManagement from "@model/PerformanceManagement";

import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";

import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import Enum from "@service/Enum";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PerformanceManagementCreateVM extends AssociatedCreateViewModel<
  PerformanceManagement,
  AssociatedRepository<PerformanceManagement>
> {
  constructor(
    @inject(TYPES.PerformanceManagementRepository) repository: AssociatedRepository<PerformanceManagement>,
    @inject(TYPES.Enum) private enums: Enum
  ) {
    super(PerformanceManagement, repository);
  }

  async create(): Promise<number | undefined> {
    const length = this.enums.values("performance_management_questions").length;
    const errors = {};
    this.entity.data_values = [];

    for (let i = 0; i < length; i++) {
      const answer_identifier = `answer${i}`;
      const answer = this.entity[answer_identifier];

      if (answer === null || answer === undefined) {
        errors[answer_identifier] = ["Odpověd je povinná"];
      }

      this.entity.data_values.push(answer);
    }

    if (Object.keys(errors).length > 0) {
      this.entity.setErrors(errors);
      return undefined;
    } else {
      this.entity.clearErrors();
    }

    return super.create();
  }
}
